<template>
    <div>
        <mdb-card class="mb-2">
            <mdb-card-header color="default">查詢</mdb-card-header>
            <mdb-card-body class="pt-0">
                <mdb-row>
                    <mdb-col md="3">
                        <mdb-select label="啟用狀態" v-model="activeList" @getValue="(val) => (search.active = val)"></mdb-select>
                    </mdb-col>
                    <mdb-col md="3">
                        <mdb-input label="商品名稱" v-model="search.name"></mdb-input>
                    </mdb-col>
                    <mdb-col md="3" class="pt-3 text-sm-right text-md-left">
                        <mdb-btn
                            color="default"
                            size="sm"
                            class="mt-3"
                            @click="
                                search.page = 1;
                                searchdata();
                            "
                        >
                            <i class="fa fa-search"></i>
                            搜&nbsp;&nbsp;尋
                        </mdb-btn>
                    </mdb-col>
                </mdb-row>
            </mdb-card-body>
        </mdb-card>
        <mdb-card>
            <mdb-card-body>
                <table class="table table-striped rwd-table-dispersion">
                    <!--Table head-->
                    <thead>
                        <tr>
                            <th style="width:4em">#</th>
                            <th class="text-nowrap" style="cursor:pointer;" @click="sort_data('name')">
                                商品名稱
                                <i
                                    class="ml-2 fa"
                                    :class="{
                                        'fa-sort': search.sortcolumn != 'name',
                                        'fa-sort-up': search.sortcolumn == 'name' && search.sort == 'asc',
                                        'fa-sort-down': search.sortcolumn == 'name' && search.sort == 'desc',
                                    }"
                                ></i>
                            </th>
                            <th class="text-nowrap" style="cursor:pointer;" @click="sort_data('active')">
                                啟用
                                <i
                                    class="ml-2 fa"
                                    :class="{
                                        'fa-sort': search.sortcolumn != 'active',
                                        'fa-sort-up': search.sortcolumn == 'active' && search.sort == 'asc',
                                        'fa-sort-down': search.sortcolumn == 'active' && search.sort == 'desc',
                                    }"
                                ></i>
                            </th>
                            <th class="text-nowrap" style="cursor:pointer;" @click="sort_data('sort')">
                                排序<i
                                    class="ml-2 fa"
                                    :class="{
                                        'fa-sort': search.sortcolumn != 'sort',
                                        'fa-sort-up': search.sortcolumn == 'sort' && search.sort == 'asc',
                                        'fa-sort-down': search.sortcolumn == 'sort' && search.sort == 'desc',
                                    }"
                                ></i>
                            </th>
                            <th class="px-0" style="width:9em"></th>
                        </tr>
                    </thead>
                    <!--Table head-->
                    <!--Table body-->
                    <tbody>
                        <tr v-for="(p, pk) in data.productList" :key="`p_${pk}`">
                            <th data-title="#">
                                {{ (data.nowPage - 1) * 10 + pk + 1 }}
                            </th>
                            <td data-title="商品名稱">{{ p.name }}</td>
                            <td data-title="啟用">{{ p.active == '0' ? '停用' : '啟用' }}</td>
                            <td data-title="排序">{{ p.sort }}</td>
                            <td class="text-right text-md-left">
                                <mdb-btn color="primary" size="sm" @click="$router.push(`/product/edit/?id_product=${p.id}`)">
                                    <i class="fa fa-edit"></i>編輯
                                </mdb-btn>
                                <mdb-btn color="danger" size="sm" @click="del_product(p.id)">
                                    <i class="fa fa-trash"></i>刪除
                                </mdb-btn>
                            </td>
                        </tr>
                        <tr>
                            <td colspan="7" scope="row" v-show="data.productList.length == 0">無資料</td>
                        </tr>
                    </tbody>
                    <!--Table body-->
                </table>
                <page
                    :nowPage="data.nowPage"
                    :totalPage="data.totalPage"
                    @switchpage="
                        (pg) => {
                            search.page = pg;
                            searchdata();
                        }
                    "
                />
            </mdb-card-body>
        </mdb-card>
    </div>
</template>
<script>
    import { mdbCard, mdbCardHeader, mdbCardBody, mdbRow, mdbCol, mdbInput, mdbBtn, mdbSelect } from 'mdbvue';
    import page from '../../components/page';
    export default {
        props: { query: { default: {} } },
        components: {
            mdbCard,
            mdbCardHeader,
            mdbCardBody,
            mdbInput,
            mdbRow,
            mdbCol,
            mdbBtn,
            mdbSelect,
            page,
        },
        data() {
            return {
                search: {
                    active: '',
                    name: '',
                    sortcolumn: '',
                    sort: '',
                    page: 1,
                },
                show_cost: false,
                activeList: [],
                search_change: false,
                data: {
                    warehouse: [],
                    productList: [],
                    nowPage: 1,
                    totalPage: 0,
                },
            };
        },
        mounted() {
            let vue = this;
            vue.put_search();
        },
        watch: {
            search: {
                handler() {
                    this.search_change = true;
                },
                deep: true,
            },
            query() {
                this.put_search();
            },
        },
        computed: {
            storeKeyList() {
                let output = {};
                this.data.storeList.forEach((item) => {
                    output[item.id] = item;
                });
                return output;
            },
        },
        methods: {
            put_search() {
                let vue = this;
                // 把篩選的資料放到變數裡面
                for (let i in vue.search) {
                    if (!vue.query[i]) {
                        switch (i) {
                            case 'page':
                                vue.search[i] = '1';
                                break;
                            default:
                                vue.search[i] = '';
                                break;
                        }
                    } else {
                        vue.search[i] = vue.query[i];
                    }
                }
                vue.activeList = [
                    { text: '全部', value: '', selected: false },
                    { text: '啟用', value: '1', selected: false },
                    { text: '停用', value: '0', selected: false },
                ];
                vue.activeList.map((item) => {
                    item.selected = item.value == vue.search.active;
                    return item;
                });
                vue.getData();
            },
            sort_data(data) {
                //
                this.search.page = 1;
                if (
                    this.search.sort == '' ||
                    this.search.sortcolumn != data ||
                    (this.search.sort == 'desc' && this.search.sortcolumn == data)
                ) {
                    this.search.sortcolumn = data;
                    this.search.sort = 'asc';
                } else if (this.search.sort == 'asc') {
                    this.search.sortcolumn = data;
                    this.search.sort = 'desc';
                } else {
                    this.search.sortcolumn = '';
                    this.search.sort = '';
                }

                this.searchdata();
            },
            // 搜尋資料
            searchdata() {
                let vue = this;
                if (vue.search_change) {
                    let url = vue.$route.path + '?';
                    for (let k in vue.search) {
                        url += `${k}=${vue.search[k]}&`;
                    }
                    vue.$router.push(url);
                    vue.getData();
                }
                vue.search_change = false;
            },
            getData() {
                let vue = this,
                    url = 'data/get_sql_data/',
                    // 搜尋模板
                    search = { data: {} },
                    query = [];
                if (vue.search.active != '') {
                    search.data.active = {
                        type: 0,
                        value: vue.search.active,
                    };
                }
                if (vue.search.name != '') {
                    search.data.name = {
                        type: 6,
                        value: '%' + vue.search.name + '%',
                    };
                }
                if (vue.search.sortcolumn) {
                    search.sort = {};
                    search.sort[vue.search.sortcolumn] = vue.search.sort == 'asc' ? 0 : 1;
                }
                // 資料搜尋
                query.push(
                    Object.assign(
                        {
                            name: 'product',
                            pg: vue.search.page,
                        },
                        search
                    )
                );
                // 資料筆數
                query.push(
                    Object.assign(
                        {
                            name: 'product',
                            num: 1,
                        },
                        search
                    )
                );
                vue.$store
                    .dispatch('get_form', {
                        payload: {
                            url: url + '?query=' + encodeURI(JSON.stringify(query)),
                        },
                    })
                    .then((res) => {
                        vue.data.productList = res.data.productList;
                        vue.data.totalPage = Math.ceil(res.data.productNumber / 10);
                        vue.data.nowPage = vue.search.page;
                    });
            },
            del_product(pid) {
                let vue = this;
                vue.$swal.fire({
                    icon: 'question',
                    title: '確定要刪除嗎?',
                    showConfirmButton: true,
                    showCancelButton: true,
                    showLoaderOnConfirm: true,
                    preConfirm() {
                        return vue.$store
                            .dispatch('post_form', {
                                payload: {
                                    url: 'product/del_product',
                                    data: {
                                        pid: pid,
                                    },
                                },
                            })
                            .then((res) => {
                                if (res.data.status == 'ok') {
                                    vue.$swal
                                        .fire({
                                            icon: 'success',
                                            title: '儲存完成!',
                                            timer: 1500,
                                            showConfirmButton: false,
                                        })
                                        .then(vue.getData);
                                }
                            });
                    },
                });
            },
        },
    };
</script>
